.App {
  text-align: center;
}

.container {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  padding: 5px;
}

button {
  padding: 5px 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}


/* ============= CONFIGURE TEAM ============= */
.configure-team-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
}


/* ============= HOME PAGE ============= */
.home-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gl-logo {
  max-width: 85%;
  width: 400px;
}

.home-input-container {
  margin-bottom: 20px;
}

.team-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 260px;
  width: 270px;
}

.team-go-button {
  margin-left: 20px;
  margin-bottom: 25px;
}

.manager-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  height: 260px;
}

.manager-input-container h3 {
  align-self: center;
}

.manager-go-button {
  align-self: center;
}

.version-text {
  font-style: italic;
}

.login-mode-switch button{
  background: none;
  color: blue;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}


/* ============= TEAM PAGE =============== */
.player-login-container {
  height: auto;
  display: flex;
  margin: 8px 0 2px 0;
  gap: 10px;
}

.login-button {
  margin-top: 8px;
}

.login-button button {
  height: 40px;
  width: 20px;
}

table, td {
  border: 1px solid black;
  text-align: left;
  margin-bottom: 20px;
}

th {
  text-align: center;
}

.game-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.suggestions li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.suggestions li:last-child {
  border-bottom: none;
}


#tp-manager-login {
  margin-top: 100px;
}

.next-game-header {
  margin: 0;
  font-weight: 600;
}

.next-game-info {
  margin: 5px 0;
  font-size: 20px;
  font-weight: 600;
}

#benches-full-msg {
  font-size: 20px;
  color: #007bff;
}

.opponent-container {
  display: flex;
  flex-direction: column;
}

.upcoming-games-container {
  margin-top: 30px;
}


/* ============= Team Notices =============== */

.team-notices-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-new-notice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-new-notice textarea {
  resize: none;
  width: 280px;
}

.notice-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.notice-item {
  white-space: pre-line;
  font-weight: 800;
  font-size: 16px;
  color: white;
  border: 10px double #bababa;
  background-color: #000;
  padding: 2%;
  width: 250px;
  min-height: 150px;
  text-align: center;
  margin: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-item p{
  width: 100%;
  font-family: "Noteworthy", sans-serif;
}

.notice-edit-buttons {
  display: none;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
  width: 96%;
}

.notice-item:hover .notice-edit-buttons {
  display: flex;
}

.notice-edit-buttons button {
  margin: 5px 0;
  border-radius: 2px;
}

.delete-notice-button {
  background-color: red;
}

.new-notice-buttons {
  margin: 10px 0;
  display: flex;
  width: 200px;
  justify-content: space-between;
}

.new-notice-buttons Button{
  width: 80px;
}

/* ============= DELETE NOTICE MODAL ============= */
.delete-notice-modal-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.delete-notice-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
}


/* ============= GAME LINEUP =============== */
#loggedin-table-title {
  margin: 0;
}
  
#max-players-header {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.login-table-edit-buttons {
  display: flex;
  flex-direction: column;
  justify-content: right;
  gap: 5px;
  width: auto;
}

.logout-player-button {
  color: white;
  background: red;
  padding: 3px 3px;
  border-radius: 3px;
  cursor: pointer;
}

.swap-player-button {
  color: white;
  background: #007bff;
  padding: 3px 3px;
  border-radius: 3px;
  cursor: pointer;
}

#gamelineup-info-button {
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  color: #007bff;
  cursor: pointer;
}

/* ============= LOGOUT MODAL =============== */

.logout-player-popup-content {
  background-color: #fff;
  width: 300px;
  margin: 100px auto; /* Center horizontally and give it some spacing from the top */
  padding: 20px;
  border: 1px solid #333;
  border-radius: 5px;
}

/* ============= AUTO LOGIN =============== */
.autologin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.autologin-player-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.autologin-player-button{
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
}

.remove-autologin-player {
  color: white;
  background: red;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
}

#autologin-table-title {
  margin: 0;
}

#autologin-table-mp {
  margin: 0;
  font-size: 12px;
}

#autologin-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 34px;
}

#autologin-info-button {
  position: absolute;
  right : 40px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
  color: #007bff;
  cursor: pointer;
}

.info-modal-title {
  margin: auto;
}

.info-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/* ============= EDIT PLAYER =============== */

.edit-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.edit-player-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.edit-player-button{
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
}

.edit-player-values {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  width: 324px;
}


/* ============= MANAGE GAMES =============== */
.game-table-header {
  margin: 5px 0;
}

.edit-game-button {
  color: white;
  background: red;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
}


/* ============= CREATE GAME =============== */
#create-game-header {
  margin: 0;
}

.create-game-message {
  text-align: left;
  z-index: 1;
  font-size: small;
  margin-top: 5px;
}

.create-game-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-game-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
  height: 240px;
  width: 280px;
}

.create-game-icon  {
  margin-right: 10px;
}

.create-game-field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notif-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}


/* ============= MANAGE PAGE =============== */
.top-section-header {
  border-top: 2px solid #333; /* Add a 2px solid line with your desired color */
  border-bottom: 2px solid #333; /* Add a 2px solid line with your desired color */
  width: 100%;
}

.section-header {
  border-bottom: 2px solid #333; /* Add a 2px solid line with your desired color */
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.section-title {
  cursor: pointer;
}

.add-player-message {
  text-align: left;
  z-index: 1;
  font-size: small;
  margin-top: 5px;
}

.add-player-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-roster-section-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-roster-section-body {
  display: flex;
  flex-direction: column;
}

.logout-cell {
  width: 10px;
}

.logout-player-button {
  background-color: red;
  border-radius: 3px;
  color: white;
}

.logout-player-popup {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000;
}

.logout-player-popup-content h2 {
  margin-top: 0;
}

.logout-player-popup-content button {
  margin: 0 10px;
  width: 50px;
}
